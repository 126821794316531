<template>
  <v-app>
      
      
    <v-btn prepend-icon="mdi-brightness-4" @click="toggleTheme"><span id="theme-label">VAI AL TEMA SCURO</span></v-btn>
    <v-main>
      <router-view/>
    </v-main>
    <v-btn elevation="8" color="pink" v-if="deferredPrompt" icon="mdi-download" @click="install" class="ml-auto" style="margin: 0 12px 12px 0;"></v-btn>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    
      deferredPrompt: null
    
  }),

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }

}


</script>

<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
  theme.global.current.value.dark ? document.getElementById('theme-label').innerHTML = 'VAI AL TEMA CHIARO' : document.getElementById('theme-label').innerHTML = 'VAI AL TEMA SCURO';

}
</script>


